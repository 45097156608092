<template>
  <a-row :gutter="24" type="flex">

    <!-- Projects Table Column -->
    <a-col :span="24" class="mb-24">

      <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
        <template #title>
          <a-row type="flex" align="middle">
            <a-col :span="24" :md="12" class="top-left-header">
              <h5 class="font-semibold m-0">Daily Content<a-spin v-if="confirmLoading"/></h5>
            </a-col>
            <a-col :span="24" :md="12" class="top-right-header">
              <a-button @click="uploadNewContentModal = true">Add New</a-button>
              <a-range-picker v-model:dateRange="dateRange" format="YYYY-MM-DD" @change="onDateChange"/>
              <a-radio-group v-model="projectHeaderBtns" size="small" @change="onStatusChange">
                <a-radio-button value="day_born_daily">Day Born</a-radio-button>
                <a-radio-button value="numerology_daily">Numerology Daily</a-radio-button>
              </a-radio-group>
            </a-col>
          </a-row>
        </template>
        <a-table :columns="columnData"
                 :data-source="featureContentList"
                 :pagination="false">

          <template slot="name" slot-scope="text">
            <a>{{ text }}</a>
          </template>

          <template slot="uploadBtn" slot-scope="row">
            <div>
              <a-button type="link" :data-id="row.key">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                     @click="showUploadModal(row.details)">
                  <path class="fill-gray-7"
                        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"/>
                  <path class="fill-gray-7"
                        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"/>
                </svg>
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                     @click="showDetailsModal(row.details)">
                  <path
                      d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"/>
                </svg>
              </a-button>
            </div>
          </template>
        </a-table>

        <div class="pagination">
          <a-pagination
              v-model:current="currentPage"
              :total="pagination.total"
              :page-size="20"
              :hideOnSinglePage="true"
              @change="onPageChange"
          />
        </div>
      </a-card>

    </a-col>
    <!-- / Projects Table Column -->


    <a-modal
        v-model:uploadNewContentModal="uploadNewContentModal"
        title="Feature Content Upload"
        :confirm-loading="confirmLoading"
        @ok="closeUploadNewContentModal"
    >
      <div class="new-upload-data">
        <a-radio-group v-model="uploadSelectedType" size="small" class="mb-5" @change="changeUploadSelectedType">
          <a-radio-button value="day_born_daily">Day Born</a-radio-button>
          <a-radio-button value="numerology_daily">Numerology Daily</a-radio-button>
        </a-radio-group>
        <a-select
            v-model:selectedTypeName="selectedTypeName"
            @change="selectedOptionChange"
            v-if="uploadSelectedType === 'day_born_daily'"
        >
          <a-select-option v-for="week in weekName"
                           :key="week"
                           :value="week">
            {{ week }}
          </a-select-option>
        </a-select>

        <a-select
            v-model:selectedTypeName="selectedTypeName"
            @change="selectedOptionChange"
            v-else
        >
          <a-select-option v-for="number in 9"
                           :key="number"
                           :value="number">
            {{ number }}
          </a-select-option>
        </a-select>
        <a-date-picker v-model:selectedDate="selectedDate"
                       format="YYYY-MM-DD"
                       :disabled-date="disabledDate"/>
        <div>
          <label>Upload Audio File</label>
          <a-input type="file" @change="onFileChange" placeholder="Basic usage" accept=".mp3,audio/*"/>
        </div>
      </div>
    </a-modal>

    <!-- Update Data-->
    <a-modal
        v-model:updateModal="updateModal"
        title="Feature Content Upload"
        :confirm-loading="confirmLoading"
        @ok="closeUploadModal"
    >
      <div class="upload-data">
        <div>ID: {{ selectedFeatureContentId }}</div>
        <div>File Link: {{ contentFileLink }}</div>
        <div>
          <label>Update Audio File</label>
          <a-input type="file" @change="onFileChange" placeholder="Basic usage" accept=".mp3,audio/*"/>
        </div>
      </div>
    </a-modal>

    <a-modal
        v-model:detailsModal="detailsModal"
        title="Feature Content Details"
        @ok="closeDetailsModal"
        @cancel="closeDetailsModal"
    >
      <div class="content-details">
        <div>ID: <span>{{ contentDetails.id }}</span></div>
        <div>Publish Date: <span>{{ contentDetails.publish_date }}</span></div>
        <div>Feature Type: <span>{{ contentDetails.feature_type === 'day_born_daily' ? 'Day Born Daily' : 'Numerology Daily' }}</span></div>
        <div>Feature Type Name: <span>{{ contentDetails.feature_type_name }}</span></div>
        <div>Content File : <span>{{ contentDetails.content_file }}</span></div>
        <audio controls v-if="Object.keys(contentDetails).length">
          <source :src="contentDetails.content_file" type="audio/wav">
          Your browser does not support the audio element.
        </audio>
      </div>
    </a-modal>

  </a-row>
</template>

<script>
import moment from "moment/moment"
import {sendRequest} from '@/http/axios.method';

const COLUMN_DATA = [
  {
    title: 'ID',
    dataIndex: 'id',
    scopedSlots: {customRender: 'id'},
    width: 90,
  },
  {
    title: 'Publish Date',
    dataIndex: 'publish_date',
    width: 200,
  },
  {
    title: 'Feature Type',
    dataIndex: 'feature_type',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Feature Type Name',
    dataIndex: 'feature_type_name',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Content',
    dataIndex: 'content_file',
    class: 'font-semibold text-muted',
  },

  {
    title: '',
    scopedSlots: {customRender: 'uploadBtn'},
    width: 200,
  },
];

const WEEK_NAME = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
]

export default {
  name: "DailyContent",
  data() {
    return {
      config: {
        'Authorization': 'Bearer ' + this.$store.state.authUser.token
      },
      columnData: COLUMN_DATA,
      featureContents: [],
      currentPage: 1,
      pagination: {},
      projectHeaderBtns: 'day_born_daily',
      modalOpen: false,
      startDate: '',
      endDate: '',
      dateRange: [],

      // Upload New Content Property
      uploadSelectedType: 'day_born_daily',
      uploadNewContentModal: false,
      selectedDate: '',
      selectedTypeName: 'sunday',
      weekName: WEEK_NAME,
      fileContent: '',
      confirmLoading: false,
      selectedFeatureContentId: '',
      selectedFeatureType: '',
      updateModal: false,
      contentFileLink: '',
      detailsModal: false,
      contentDetails: [],
    }
  },
  created() {
    this.getFeatureContents()
  },
  methods: {
    disabledDate(event) {
      return moment().isSameOrAfter(moment(event));
    },
    onDateChange() {
      this.startDate = '';
      this.endDate = '';

      if (Object.keys(this.dateRange).length) {
        this.startDate = moment(this.dateRange[0]).format('YYYY-MM-DD')
        this.endDate = moment(this.dateRange[1]).format('YYYY-MM-DD')
      }

      this.getFeatureContents()
    },
    onPageChange() {
      this.getFeatureContents()
    },
    onStatusChange() {
      this.getFeatureContents()
    },
    getFeatureContents() {
      let data = {
        page: this.currentPage,
        type: this.projectHeaderBtns,
        start_date: this.startDate,
        end_date: this.endDate
      }
      this.confirmLoading = true
      sendRequest('get', 'admin/feature-contents', data, this.config)
          .then(response => {
            this.confirmLoading = false
            if (response.success) {
              let data = response.data.feature_content
              this.featureContents = data.map(function (content, label) {
                return {
                  key: label + 1,
                  id: content.id,
                  publish_date: content.publish_date,
                  feature_type: content.feature_type === 'day_born_daily' ? 'Day Born Daily' : 'Numerology Daily',
                  feature_type_name: content.feature_type_name,
                  content_file: content.content_file,
                  details: content
                };
              });
              this.pagination = response.data.meta.pagination
              this.currentPage = this.pagination.current_page
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
            }
          })
          .catch(() => {
            this.confirmLoading = false
          })
          .finally(() => {
            this.confirmLoading = false
          })
    },
    showUploadModal(content) {
      this.updateModal = true;
      this.selectedFeatureContentId = content.id;
      this.selectedFeatureType = content.feature_type;
      this.contentFileLink = content.content_file;
    },
    onFileChange(e) {
      this.fileContent = e.target.files[0];
    },
    changeUploadSelectedType() {
      this.selectedTypeName = this.uploadSelectedType === 'day_born_daily' ? 'sunday' : 1;
    },
    selectedOptionChange() {
      console.log('option name', this.selectedTypeName)
    },
    closeUploadNewContentModal() {
      this.confirmLoading = true;
      let formData = new FormData();
      let configHeader = {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.$store.state.authUser.token
      }
      formData.append('publish_date', moment(this.selectedDate).format('YYYY-MM-DD'));
      formData.append('feature_type', this.uploadSelectedType);
      formData.append('feature_type_name', this.selectedTypeName);
      formData.append('content_file', this.fileContent);

      sendRequest('post', 'admin/feature-content-upload', formData, configHeader)
          .then(response => {
            if (response.success) {
              this.confirmLoading = false;
              this.uploadNewContentModal = false;
              this.fileContent = '';
              this.uploadSelectedType = 'day_born_daily';
              this.selectedTypeName = 'sunday';
              this.getFeatureContents()
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
            }

          })
          .catch(() => {
            this.confirmLoading = false;
          })
          .finally(() => {
            this.confirmLoading = false;
          })
    },
    closeUploadModal() {
      this.confirmLoading = true;
      let formData = new FormData();
      let configHeader = {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.$store.state.authUser.token
      }
      formData.append('feature_content_id', this.selectedFeatureContentId);
      formData.append('feature_type', this.selectedFeatureType);
      formData.append('content_file', this.fileContent);
      sendRequest('post', 'admin/feature-content-update', formData, configHeader)
          .then(response => {
            if (response.success) {
              this.confirmLoading = false;
              this.updateModal = false;
              this.selectedFeatureContentId = '';
              this.selectedFeatureType = '';
              this.fileContent = '';
              this.getFeatureContents()
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
            }

          })
          .catch(() => {
            this.confirmLoading = false;
          })
          .finally(() => {
            this.confirmLoading = false;
          })
    },
    showDetailsModal(content) {
      this.detailsModal = true;
      this.contentDetails = content;
    },
    closeDetailsModal() {
      this.contentDetails = [];
      this.detailsModal = false;
    }
  },
  computed: {
    featureContentList: function () {
      return this.featureContents;
    }
  }
}
</script>

<style scoped>

</style>
